import { CircularProgress } from "@mui/material";
import styled from "styled-components";

export const UploadFileStyled = styled.div`
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.border.grey};
  padding: 25px;
  border-radius: 8px;
  min-width: 500px;
  label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &:hover {
      cursor: pointer;
    }
    .uploadFile {
      width: 100%;
      height: 100%;
      display: none;
    }
    .url {
      position: absolute;
      left: 15px;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .uploadFileIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const UploadFileIconLoader = styled(CircularProgress)`
  width: 25px !important;
  height: 25px !important;
`;
