import { CommonContext } from "./CommonContext";
import { useCommon } from "./useCommon";

const DetailsProvider = ({ children }: any) => {
  const value = useCommon();
  return (
    <CommonContext.Provider value={value}>{children}</CommonContext.Provider>
  );
};

export default DetailsProvider;
