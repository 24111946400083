import { FC, useState } from "react";

import Label from "../Label/Label";
import Error from "../Error/Error";
import { CardInputStyle } from "./CardInput.styles";
import { ICardInputType } from "./CardInput.types";
import CardIcon from "./assets/icons/CardIcon";

const CardInput: FC<ICardInputType> = ({
  label = "",
  error,
  disabled = false,
  placeholder = "0000 0000 0000 0000",
  className = "",
  register,
  setValue,
  name,
}) => {
  const [cardNumber, setCardNumber] = useState<string>("");
  const change = (e: any) => {
    let eventValue = e.target.value;
    var notCharacter = eventValue.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    var stringNumberSpace = notCharacter
      .split("")
      .reverse()
      .join("")
      .replace(/(\d)(?=(\d{4})+(?!\d))/g, "$& ")
      .split("")
      .reverse()
      .join("");
    if (
      (cardNumber.length === 19
        ? stringNumberSpace.length
        : cardNumber.length) < 19
    ) {
      setCardNumber(stringNumberSpace);
      setValue(name, stringNumberSpace);
    } else {
      setValue(name, cardNumber);
    }
  };
  return (
    <div className={className}>
      {label && <Label label={label} />}
      <CardInputStyle>
        <input
          placeholder={placeholder}
          disabled={disabled}
          {...register(name, {
            required: { value: true, message: "Kiritish majburiy!" },
          })}
          className={`${!!error ? "error" : ""}`}
          onChange={change}
        />
        <div className="card-image">
          <CardIcon />
        </div>
      </CardInputStyle>
      {!!error && <Error message={error.message} />}
    </div>
  );
};

export default CardInput;
