import styled from "@emotion/styled";

export const ChangeLanguageStyled = styled.div`
  color: black;
  #demo-positioned-button {
    background: #e6fbf4;
    border-radius: 35px;
    padding: 10px 3.5px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    box-shadow: none !important;
    cursor: pointer;
    border: none;
    color: black;
    font-weight: 500;
    margin-right: 13px;
  }
`;
