import styled from "@emotion/styled";
import TextareaAutosize from "@mui/material/TextareaAutosize";

export const StyledTextArea = styled.div`
  label {
    display: block;
    margin-bottom: 5px;
  }
`;
export const TextAreaStyled = styled(TextareaAutosize)`
  display: block;
  border-radius: 8px;
  border: 1px solid rgba(49, 57, 73, 0.1);
  color: #313949;
  padding: 16px;
  font-style: normal;
  font-weight: normal;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 18px;
  outline: none;
  transition: all 300ms ease-out;
  width: 100% !important;
  &.error {
    border-color: red;
  }
`;
