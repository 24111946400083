import { useEffect, useState } from "react";

import { useRequest } from "hooks";
import { TableDataType } from "hooks/useRequest/useRequest.types";
import { IRoles } from "services/roleManagement/role.types";
import browserStorage from "services/storage/browserStorage";
import { useTranslation } from "react-i18next";
import { TMapTypes } from "pages/settings/map/container/Map.constants";
import { ILoginResponse } from "pages/login/context/LoginContext.types";
import { IEmployeeRoleData } from "./CommonContext.types";
import { IClientCall } from "pages/orders/OpenStreetMap_Order/listAction/components/table/Calls/container/OrderCalls.types";
import { get } from "lodash";
import { useNavigate } from "react-router-dom";

export interface IAdmin {
  isOnlie: boolean;
  _id: string;
  isDeleted: boolean;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  password: string;
  roleId: string;
  agentId: string | null;
  createdAt: string;
  updatedAt: string;
}
export interface ISettingsFeatures {
  _id: string;
  incrementalId: number;
  updatedBy: string;
  updatedByUserId: null;
  updatedByDriverId: null;
  updatedByEmployeeId: null;
  driverNegativeBalance: boolean;
  phoneOnOrders: boolean;
  orderNoDriverCancelTime: number;
  orderNoDriverTime: number;
  outerCityPrice: false;
  createdAt: string;
  updatedAt: string;
  phonePrefix: string;
  languages: string[];
  appLogo: string;
  appTitle: string;
  driverMaxActiveOrders?: number;
  userMaxActiveOrders?: number;
  requireDriverLicensePhoto?: boolean;
  requireDriverCarPhoto?: boolean;
  driverUniqueDevice?: boolean;
  allowDriverRegister?: boolean;
  orderMaxDisplayDuration?: number;
  filterControlEnabled?: boolean;
  orderMinDisplayDuration?: number;
  mapType: TMapTypes;
  center: {
    longitude: number;
    latitude: number;
    name: string;
    description: string;
  };
  currency: string;
  pendingRadius: number;
  searchingRadius: number;
  sendSmsByFirebase: boolean;
  showFreeDriversOnly: boolean;
  value?: any;
}
export type RUseCommon = ReturnType<typeof useCommon>;

export const useCommon = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [render, setRender] = useState<boolean>(false);
  // const [newOrder, setNewOrder] = useState<any>();
  // const [updatedOrder, setUpdatedOrder] = useState<any>();
  const [newDriver, setNewDriver] = useState<any>();
  const [photoCheck, setPhotoCheck] = useState<any>();
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
  const [loginData, setLoginData] = useState<ILoginResponse>();
  const [newClientCall, setNewClientCall] = useState<IClientCall>();
  const [notificationsLength, setNotificationsLength] = useState(
    Number(browserStorage.get("notificationsLength")) || 0
  );
  const [chatId, setChatId] = useState<string>("");
  const [chatRead, setChatRead] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const defaultChat = localStorage.getItem("chatName");
  const [chatTitle, setChatTitle] = useState(
    localStorage.getItem("chatTitle") || "driver"
  );

  const [getRoleClient, getRoleData, getRoleStatus, getRoleError] =
    useRequest<ILoginResponse>();
  const [
    getNotificationsClient,
    getNotificationsData,
    getNotificationsStatus,
    getNotificationsError,
  ] = useRequest();

  const [makeReadClient, makeReadData, makeReadStatus, makeReadError] =
    useRequest();

  const [getChatsClient, getChatsData, getChatsStatus, getChatsError] =
    useRequest();

  const admin: IAdmin =
    localStorage.getItem("admin") &&
    JSON.parse(localStorage.getItem("admin") || "");
  const id = admin?._id;

  const getRole = async (idDefault: string | undefined) => {
    await getRoleClient.get(`employee/${idDefault || id}`);
  };

  const getNotifications = async (limit: number = 15) => {
    await getNotificationsClient.get(`notification?page=1&limit=${limit}`);
  };

  const makeRead = async (id: string) => {
    await makeReadClient.get(`notification/${id}`);
  };

  const getChats = async (sender: string, search?: string) => {
    await getChatsClient.get(
      `message/chats?sender=${sender}${search ? `&search=${search}` : ""}`
    );
  };

  const [phoneNumbersClient, phoneNumbersData] =
    useRequest<TableDataType<any>>();

  const getPhoneNumbers = async () => {
    await phoneNumbersClient.get(`phone`);
  };

  const [
    getDefaultPhoneClient,
    getDefaultPhoneData,
    getDefaultPhoneStatus,
    getDefaultPhoneError,
  ] = useRequest();

  const getDefaultPhone = () => {
    getDefaultPhoneClient.get("settings/feature").then((response: any) => {
      if (
        !response?.data?.languages?.find(
          (lang: string) => lang === localStorage.getItem("i18nextLng")
        )
      ) {
        if (response?.data?.languages?.length) {
          i18n.changeLanguage(response?.data?.languages[0]);
        }
      }
    });
  };

  const [
    getFeaturesClient,
    getFeaturesData,
    getFeaturesStatus,
    getFeaturesError,
  ] = useRequest<ISettingsFeatures>();
  const getFeatures = async () => {
    await getFeaturesClient.get(`settings/feature`);
  };

  useEffect(() => {
    getFeatures();
    if (id) getRole(undefined);
  }, []);

  useEffect(() => {
    getDefaultPhone();
  }, []);

  useEffect(() => {
    if (getRoleStatus === "SUCCESS") {
      if (!loginData) {
        setLoginData(getRoleData.data);
      }
      const agentId = getRoleData.data?.agentId;
      if (agentId) {
        browserStorage.set("agentId", agentId);
      } else {
        browserStorage.remove("agentId");
      }
    }
  }, [getRoleStatus]);

  // to update profile info on navbar😐
  const updateWindow = async ({
    loginData,
    willNavigate = true,
    willGetRole = true,
  }: {
    loginData: any;
    willNavigate?: boolean;
    willGetRole?: boolean;
  }) => {
    await browserStorage.set("token", get(loginData, "data.token"));
    await browserStorage.set("admin", get(loginData, "data"));
    const agentId = loginData.data?.agentId;
    if (agentId) await browserStorage.set("agentId", agentId);
    if (willGetRole) await getRole(get(loginData, "data")?._id);
    if (willNavigate) await navigate("/");
    setLoginData(loginData?.data);
  };
  const agentId = localStorage.getItem("agentId");

  return {
    state: {
      getRoleState: {
        getRoleData,
        getRoleStatus,
        getRoleError,
      },
      getDefaultPhoneState: {
        getDefaultPhoneData,
        getDefaultPhoneStatus,
        getDefaultPhoneError,
      },
      getNotificationsState: {
        getNotificationsData,
        getNotificationsStatus,
        getNotificationsError,
      },
      makeReadState: {
        makeReadData,
        makeReadStatus,
        makeReadError,
      },
      getChatsState: {
        getChatsData,
        getChatsStatus,
        getChatsError,
      },
      getFeaturesState: {
        getFeaturesData,
        getFeaturesStatus,
        getFeaturesError,
      },
      render,
      // updatedOrder,
      newDriver,
      photoCheck,
      notificationsLength,
      notificationOpen,
      chatId,
      chatRead,
      admin,
      // newOrder,
      phoneNumbersData,
      loginData,
      isAgent: !!agentId,
      newClientCall,
      phoneNumber,
      chatTitle,
    },
    actions: {
      getRole,
      setRender,
      setChatTitle,
      getDefaultPhone,
      // setUpdatedOrder,
      setNewDriver,
      setPhotoCheck,
      setNotificationsLength,
      getNotifications,
      setNotificationOpen,
      makeRead,
      setChatId,
      getChats,
      setChatRead,
      // setNewOrder,
      getPhoneNumbers,
      getFeatures,
      setLoginData,
      setNewClientCall,
      setPhoneNumber,
      updateWindow,
    },
  };
};
