import styled from "styled-components";

export const NotificationsStyled = styled.div`
  position: relative;
  margin-right: 20px;
  .bell {
    cursor: pointer;
  }
  .wrapper {
    position: absolute;
    right: -10px;
    top: 27px;
    width: 450px;
    background-color: #fff;
    color: #000;
    box-shadow: 0px 2px 10px 0px rgba(130, 126, 126, 0.2);
    overflow-y: auto;
    max-height: calc(100vh - 49px);
    z-index: 1000;
    h3 {
      padding: 15px 0 10px 15px;
    }
    .link {
      text-decoration: none;
      color: #000;
    }
  }
  .item {
    position: relative;
    padding: 15px;
    padding-left: 19px;
    cursor: pointer;
    &:hover {
      background-color: #f1f1f1;
    }
    p {
      margin-bottom: 4px;
    }
    time {
      font-size: 15px;
      opacity: 0.6;
    }
  }
  .isRead {
    position: absolute;
    top: 50%;
    margin-top: -3px;
    left: 7px;
    width: 5px;
    height: 5px;
    background-color: #329674;
    border-radius: 5px;
  }
  .no-notifications {
    padding: 15px;
  }
  .fetch-more-btn-wrapper {
    text-align: center;
    padding: 12px 0;
  }
  .fetch-more-btn {
    border: none;
    background-color: #4ce5b1;
    padding: 6px 15px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
  }
`;
