import styled from "styled-components";

export const StyledApp = styled.div<any>`
  /* background-color: ${({ theme }) => theme.colors.bg.mainBg};
  width: 100%;
  min-height: 100vh;
  transition: all 0.3s linear;
  padding-left: ${(props) =>
    props.collapse ? "max(20vw + 30px, 300px)" : "max(110px, 110px)"};
  padding-top: 100px;
  padding-right: 30px;
  padding-bottom: 30px; */
  background-color: ${({ theme }) => theme.colors.bg.mainBg};
  width: 100%;
  padding-left: ${({ collapse }) => (!collapse ? "270px" : "80px")};
  /* min-height: 100vh; */
  transition: width, left, right, 0.3s;
  /* padding-left: ${(collapse) =>
    !collapse ? "max(20vw + 30px, 300px)" : "max(300px, 300px)"}; */
  /* padding-top: 100px;
  padding-right: 30px;
  padding-bottom: 30px; */
`;
