import { Button } from "@mui/material";
import styled from "styled-components";

export const LoginButtonStyled = styled(Button)`
  background-color: ${({ theme }) => theme.colors.button.blue} !important;
  padding: 12px 32px !important;
  border-radius: 8px !important;
  text-transform: capitalize !important ;
  width: 100%;
  font-size: 18px !important;
  .loading_animation {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }
`;
