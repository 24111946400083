import { FC, useState } from "react";

import Label from "../Label/Label";
import Error from "../Error/Error";
import { IExpireDateType } from "./ExpireDateInput.types";
import { ExpireDateInputStyle } from "./ExpireDateInput.styles";

const ExpireDateInput: FC<IExpireDateType> = ({
  label = "",
  error,
  disabled = false,
  placeholder = "MM/YY",
  className = "",
  register,
  setValue,
  name,
}) => {
  const [expireDate, setExpireDate] = useState<string>("");

  const change = (e: any) => {
    let eventValue = e.target.value;
    var notCharacter = eventValue.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    var stringNumberSpace = notCharacter
      .split("")
      .reverse()
      .join("")
      .replace(/(\d)(?=(\d{2})+(?!\d))/g, "$&/")
      .split("")
      .reverse()
      .join("");
    if (
      (expireDate.length === 5 ? stringNumberSpace.length : expireDate.length) <
      5
    ) {
      setExpireDate(stringNumberSpace);
      setValue(name, stringNumberSpace);
    } else {
      setValue(name, expireDate);
    }
  };
  return (
    <div className={className}>
      {label && <Label label={label} />}
      <ExpireDateInputStyle
        placeholder={placeholder}
        disabled={disabled}
        {...register(name, {
          required: { value: true, message: "Kiritish majburiy!" },
        })}
        className={`${!!error ? "error" : ""}`}
        onChange={change}
      />
      {!!error && <Error message={error.message} />}
    </div>
  );
};

export default ExpireDateInput;
